import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Sobremesas = () => (
  <>
    <SEO title="Sobremesas" />

    <Menu className="menu">
      <Slide left cascade duration={300}>
        <h1 className="menu-title">Sobremesas</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Sorvete Taça</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>

        <p className="item-desc">Consulte sabores.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Milk Shake</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Sabores: Ovomaltine e Morango</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Açaí Taça</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>

        <p className="item-desc">
          A taça vai com dois acompanhamentos grátis. Acompanhamentos adicionais
          R$ 3,00 cada. <br />
          <br /> Leite condensado, Leite ninho, Ouro branco e Confete. <br />
          <br />
          Frutas: Banana, Morango e Uva.
          <br />
          <br />
          Cremes: Creme de ninho, Creme de morango e Creme de avelã.
        </p>
      </Item>
    </Menu>
  </>
);

export default Sobremesas;
